@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../assets/fonts/Poppins.ttf) format("truetype");
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(../assets/fonts/Outfit.ttf) format("truetype");
}

body {
  margin: 0;
  height: 100vh;
}

#root {
  height: 95vh;
  padding-top: 1rem;
}

.icon-marker {
  font-size: 1.5rem;
}

.icon-order-marker {
  color: rgba($color: #49313d, $alpha: 1);
}

.leaflet-div-icon {
  background-color: transparent !important;
  border: none !important;
}

.swal2-html-container {
  font-family: "Poppins";
}

.swal2-styled.swal2-confirm {
  background-color: #1678c5 !important;
}

// .btn-secondary {
//   background-color: #ffc184 !important;
//   border-color: #ffc184 !important;
//   color: white !important;
// }

// .pull-right{
//   float: right !important;
// }
